import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Card = ({ article }) => {
  return (
    <div>
      {article.node.externalLink ? (
        <a href={`${article.node.externalLink}`} target="_blank">
          <div className="relative flex" style={{ height: "100%" }}>
            <img
              src={article.node.image.url}
              alt={`Hero image`}
              className="absolute inset-0 w-full h-full object-center object-cover rounded-xl"
              style={{ height: "100%" }}
            />
            <div
              class=" relative w-full flex flex-col bg-black bg-opacity-40 justify-between p-4 text-white rounded-xl shadow-lg"
              style={{ height: "100%" }}
            >
              <div class="flex gap-4">
                {article.node.categories.map((category, i) => (
                  <Link
                    to={`/service-categories/${category.slug}`}
                    className="px-3 py-1 border rounded-full"
                  >
                    {category.title}
                  </Link>
                ))}
              </div>
              <div>
                <p class="font-semibold">{article.node.title}</p>
                <span class="">{article.node.description}</span>
              </div>
            </div>
          </div>
        </a>
      ) : (
        <Link to={`/services/${article.node.slug}`}>
          <div className="relative flex" style={{ height: "100%" }}>
            <img
              src={article.node.image.url}
              alt={`Hero image`}
              className="absolute inset-0 w-full h-full object-center object-cover rounded-xl"
              style={{ height: "100%" }}
            />
            <div
              class=" relative w-full flex flex-col bg-black bg-opacity-40 justify-between p-4 text-white rounded-xl shadow-lg"
              style={{ height: "100%" }}
            >
              <div class="flex gap-4">
                {article.node.categories.map((category, i) => (
                  <Link
                    to={`/service-categories/${category.slug}`}
                    className="px-3 py-1 border rounded-full"
                  >
                    {category.title}
                  </Link>
                ))}
              </div>
              <div>
                <p class="font-semibold">{article.node.title}</p>
                <span class="">{article.node.description}</span>
              </div>
            </div>
          </div>
        </Link>
      )}
    </div>
  )
}

export default Card
