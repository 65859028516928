import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

const Categories = () => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiCategories {
            edges {
              node {
                slug
                title
              }
            }
        }
      }
    `}
    render={(data) => (
    <div className="gap-8 font-bold">
        <Link to="/services" className="inline-block px-6 m-2 py-1 border border-black rounded-full" activeClassName="text-white bg-black">
            All Services
        </Link>
        {data.allStrapiCategories.edges.map((category, i) => {
            return (
                <Link to={`/service-categories/${category.node.slug}`} className="inline-block text-primary px-6 m-2 py-1 border border-black rounded-full hover:bg-navbar hover:text-white hover:border-none" activeClassName="text-white bg-black">
                    {category.node.title}
                </Link>
            )
        })}
    </div>
  )}
  />
);

export default Categories;